/**
 * Данная функция принимает строку URL с приставкой языка и возвращает приставку языка
 * @param pathname - текущий полный путь
 * @returns Возвращает текущую локаль - string
 */
export function getLocaleFromPath(pathname: string) {
  const urlWithLocale = pathname.split('/')[1];

  return urlWithLocale;
}
