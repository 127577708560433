export enum LocalTextOfMethodsRU {
  errorLocalesText = 'На сервере произошла ошибка, попробуйте чуть позже',
  errorMessageDataRequest = 'По вашему запросу нет подходящей информации в документах',
  errorNotFoundedText = 'Документ не найден',
  documentsReloadSuccess = 'Документ был успешно загружен!',
  documentsReloadError = 'Упс! Документ не был загружен!',
  documentsDeleteSuccess = 'Документ был успешно удален!',
  documentsUploadSuccess = 'Документ был успешно загружен!',
  contactWithUsSuccess = 'Сообщение было успешно отправлено!',
  folderDeleteSuccess = 'Папка была успешно удалена!',
  buissnessDeleteSuccess = 'Бизнес-функция была успешно удалена!',
}
export enum LocalTextOfMethodsEN {
  errorLocalesText = 'An error occurred on the server, try again later',
  errorMessageDataRequest = 'There is no suitable information in the documents for your request',
  errorNotFoundedText = 'Document not found',
  documentsReloadSuccess = 'Document was loaded successfully!',
  documentsReloadError = 'Oops! Document was not loaded!',
  documentsDeleteSuccess = 'Document was deleted successfully!',
  documentsUploadSuccess = 'Document was loaded successfully!',
  contactWithUsSuccess = 'The message was sent successfully!',
  folderDeleteSuccess = 'The folder was deleted successfully!',
}
