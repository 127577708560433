// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable max-statements */
import { getLocaleFromPath } from '@/shared/lib/getLocaleFromPath';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';
import { storeAdmin } from '@/shared/store/admin';
import ru_data from '@/shared/locales/ru.json';
import en_data from '@/shared/locales/en.json';
import { localType } from './types';
import { LocalTextOfMethodsEN, LocalTextOfMethodsRU } from './constant';

export function useCurrentLocale() {
  const isOnlyEn = process.env.NEXT_PUBLIC_IS_ONLY_EN;
  const pathname = usePathname();
  const pathnameWithLocal = isOnlyEn ? '' : getLocaleFromPath(pathname);
  const locale: Record<string, localType> = {
    ru: ru_data,
    en: en_data,
  };
  const currentLocal = isOnlyEn ? locale.en : locale[pathnameWithLocal];
  const isEnVersion = pathnameWithLocal === 'en' || false;
  useEffect(() => {
    if (isOnlyEn) {
      storeAdmin.errorLocalesText = LocalTextOfMethodsEN.errorLocalesText;
      storeAdmin.errorMessageDataRequest =
        LocalTextOfMethodsEN.errorMessageDataRequest;
      storeAdmin.errorNotFoundedText = LocalTextOfMethodsEN.errorNotFoundedText;
      storeAdmin.textOfAlers.documents.reload.success =
        LocalTextOfMethodsEN.documentsReloadSuccess;
      storeAdmin.textOfAlers.documents.reload.error =
        LocalTextOfMethodsEN.documentsReloadError;
      storeAdmin.textOfAlers.documents.delete.success =
        LocalTextOfMethodsEN.documentsDeleteSuccess;
      storeAdmin.textOfAlers.documents.upload.success =
        LocalTextOfMethodsEN.documentsUploadSuccess;
      storeAdmin.textOfAlers.contactWithUs.success =
        LocalTextOfMethodsEN.contactWithUsSuccess;
      storeAdmin.textOfAlers.folder.delete.success =
        LocalTextOfMethodsEN.folderDeleteSuccess;
    } else if (pathnameWithLocal === 'ru') {
      storeAdmin.errorLocalesText = LocalTextOfMethodsRU.errorLocalesText;
      storeAdmin.errorMessageDataRequest =
        LocalTextOfMethodsRU.errorMessageDataRequest;
      storeAdmin.errorNotFoundedText = LocalTextOfMethodsRU.errorNotFoundedText;
      storeAdmin.textOfAlers.documents.reload.success =
        LocalTextOfMethodsRU.documentsReloadSuccess;
      storeAdmin.textOfAlers.documents.reload.error =
        LocalTextOfMethodsRU.documentsReloadError;
      storeAdmin.textOfAlers.documents.delete.success =
        LocalTextOfMethodsRU.documentsDeleteSuccess;
      storeAdmin.textOfAlers.documents.upload.success =
        LocalTextOfMethodsRU.documentsUploadSuccess;
      storeAdmin.textOfAlers.contactWithUs.success =
        LocalTextOfMethodsRU.contactWithUsSuccess;
      storeAdmin.textOfAlers.folder.delete.success =
        LocalTextOfMethodsRU.folderDeleteSuccess;
    } else {
      storeAdmin.errorLocalesText = LocalTextOfMethodsEN.errorLocalesText;
      storeAdmin.errorMessageDataRequest =
        LocalTextOfMethodsEN.errorMessageDataRequest;
      storeAdmin.errorNotFoundedText = LocalTextOfMethodsEN.errorNotFoundedText;
      storeAdmin.textOfAlers.documents.reload.success =
        LocalTextOfMethodsEN.documentsReloadSuccess;
      storeAdmin.textOfAlers.documents.reload.error =
        LocalTextOfMethodsEN.documentsReloadError;
      storeAdmin.textOfAlers.documents.delete.success =
        LocalTextOfMethodsEN.documentsDeleteSuccess;
      storeAdmin.textOfAlers.documents.upload.success =
        LocalTextOfMethodsEN.documentsUploadSuccess;
      storeAdmin.textOfAlers.contactWithUs.success =
        LocalTextOfMethodsEN.contactWithUsSuccess;
      storeAdmin.textOfAlers.folder.delete.success =
        LocalTextOfMethodsEN.folderDeleteSuccess;
    }
  }, [isOnlyEn, pathnameWithLocal]);
  return {
    currentLocal,
    isOnlyEn,
    isEnVersion,
  };
}
